.comments-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* min-height: 100vh; */
    padding: 10px;
    width: 90%;
    max-height: 512px;
    margin: auto;
    background-color: #f9f9f9;
    /* overflow-y: scroll; */
    /* overflow: hidden; */
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  input,
  textarea {
    width: 300px;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .comments-list {
    width: 80%;
    max-width: 600px;
  }
  
  .comment {
    background-color: white;
    padding: 5px;
    margin: 5px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  